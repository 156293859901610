
import { Component, Inject, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { QuickPurchaseData } from "@/types/localtypes";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";

@Component({
  name: "QuickPurchaseError",
  components: { QuickPurchasePageTitle },
})
export default class QuickPurchaseError extends Vue {
  @Inject() readonly quickPurchaseBus!: Vue;
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;

  get hasServerName() {
    return !!this.quickPurchaseData.serverName;
  }

  get contactUsWrapperClasses() {
    const classes: string[] = [];
    if (!this.hasServerName) {
      classes.push("pt-2");
    }
    return classes;
  }

  toPlatform() {
    this.quickPurchaseBus.$emit("shouldGoToPlatform");
  }

  mounted() {
    this.quickPurchaseBus.$emit("shouldDisplayHostedPlanCta", false);
  }
}
